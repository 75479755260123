// tslint:disable:no-http-string
import { Link } from 'gatsby'
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'

import { SocialLink } from '../../styles/shared'
import config from '../../website-config'
import Twitter from '../icons/twitter'
import LinkedIn from '../icons/linkedin'
import GitHub from '../icons/github'
import SiteNavLogo from './SiteNavLogo'

const HomeNavRaise = css`
  @media (min-width: 900px) {
    position: relative;
    top: -70px;
  }
`

const SiteNavStyles = css`
  position: relative;
  z-index: 300;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: hidden;
  height: 40px;
  font-size: 1.2rem;
`

const SiteNavLeft = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  padding-bottom: 80px;
  letter-spacing: 0.4px;
  white-space: nowrap;

  -ms-overflow-scrolling: touch;

  @media (max-width: 700px) {
    margin-right: 0;
    padding-left: 4vw;
  }
`

const NavStyles = css`
  display: flex;
  margin: 0 0 0 0;
  padding: 0;
  list-style: none;

  li {
    display: block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  li a {
    display: block;
    margin: 0;
    padding: 10px 12px;
    color: #fff;
    opacity: 0.8;
    text-shadow: 1px 1px 1px #000;
  }

  li a:hover {
    text-decoration: none;
    opacity: 1;
  }
`

const SiteNavRight = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 40px;

  @media (max-width: 700px) {
    display: none;
  }
`

const SocialLinks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  a:last-of-type {
    padding-right: 20px;
  }
`

const SubscribeButton = styled.a`
  display: block;
  padding: 4px 10px;
  border: #fff 1px solid;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1em;
  border-radius: 10px;
  opacity: 0.8;

  :hover {
    text-decoration: none;
    opacity: 1;
    cursor: pointer;
  }
`

class SiteNav extends Component {
  subscribe = React.createRef()

  openModal = () => {
    if (this.subscribe.current) {
      this.subscribe.current.open()
    }
  }

  render() {
    const { isHome = false } = this.props
    return (
      <nav css={[isHome && HomeNavRaise, SiteNavStyles]}>
        <SiteNavLeft>
          {!isHome && <SiteNavLogo />}
          <ul css={NavStyles} role="menu">
            {/* TODO: mark current nav item - add class nav-current */}
            <li role="menuitem">
              <Link to="/">Home</Link>
            </li>
            <li role="menuitem">
              <Link to="/about-me">About me</Link>
            </li>
          </ul>
        </SiteNavLeft>
        <SiteNavRight>
          <SocialLinks>
            {config.github && (
              <a css={SocialLink} href={config.github} target="_blank" title="GitHub" rel="noopener noreferrer">
                <GitHub />
              </a>
            )}
            {config.linkedIn && (
              <a css={SocialLink} href={config.linkedIn} target="_blank" title="LinkedIn" rel="noopener noreferrer">
                <LinkedIn />
              </a>
            )}
            {config.twitter && (
              <a css={SocialLink} href={config.twitter} title="Twitter" target="_blank" rel="noopener noreferrer">
                <Twitter />
              </a>
            )}
          </SocialLinks>
          {config.showSubscribe && <SubscribeButton onClick={this.openModal}>Subscribe</SubscribeButton>}
        </SiteNavRight>
      </nav>
    )
  }
}

SiteNav.propTypes = {
  isHome: PropTypes.bool,
}

SiteNav.defaultProps = {
  isHome: false,
}

export default SiteNav
