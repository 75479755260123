import { Link, StaticQuery, graphql } from 'gatsby'
import { setLightness } from 'polished'
import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Img from 'gatsby-image'

import { colors } from '../styles/colors'
import { outer, inner } from '../styles/shared'
import config from '../website-config'

const SiteFooter = css`
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  background: ${setLightness('0.0015', colors.darkgrey)};
`

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.3rem;
  a {
    color: rgba(255, 255, 255, 0.7);
  }
  a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`

const SiteFooterNav = styled.nav`
  display: flex;

  a {
    position: relative;
    margin-left: 20px;
  }

  a:before {
    content: '';
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
  }

  a:first-of-type:before {
    display: none;
  }
  @media (max-width: 650px) {
    a:first-of-type {
      margin-left: 0;
    }
  }
`

const LogoCss = css`
  height: 15px;
  width: 15px;
`

const LogoWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85px;
`

const Footer = () => (
  <StaticQuery
    query={graphql`
      query logos {
        gatsby: file(relativePath: { eq: "img/gatsby.png" }) {
          childImageSharp {
            fluid(maxWidth: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contentful: file(relativePath: { eq: "img/contentful.png" }) {
          childImageSharp {
            fluid(maxWidth: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    // tslint:disable-next-line:react-this-binding-issue
    render={data => (
      <footer css={[outer, SiteFooter]}>
        <div css={[inner, SiteFooterContent]}>
          <section className="copyright">
            <Link to="/">{config.title}</Link> &copy; {new Date().getFullYear()}
          </section>
          <div css={LogoWrapper}>
            <Img
              alt="gatsby logo"
              imgStyle={{ objectFit: 'contain' }}
              css={LogoCss}
              fluid={data.gatsby.childImageSharp.fluid}
            />
            <span>+</span>
            <Img
              alt="contentful logo"
              imgStyle={{ objectFit: 'contain' }}
              css={LogoCss}
              fluid={data.contentful.childImageSharp.fluid}
            />
            <span>=</span>
            <span>❤️</span>
          </div>
          <SiteFooterNav>
            <Link to="/">Latest Posts</Link>
            {config.github && (
              <a href={config.github} target="_blank" rel="noopener noreferrer">
                GitHub
              </a>
            )}
            {config.linkedIn && (
              <a href={config.linkedIn} target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
            )}
            {config.twitter && (
              <a href={config.twitter} target="_blank" rel="noopener noreferrer">
                Twitter
              </a>
            )}
          </SiteFooterNav>
        </div>
      </footer>
    )}
  />
)

export default Footer
