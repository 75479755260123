import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const Head = props => {
  const { article, data } = props
  const { buildTime, config } = data.site

  const composeImage = img => {
    let image = img
    if (!image) image = data?.header?.fluid?.src
    if (image.includes('//')) {
      if (image.includes('http')) return image
      return `https:${image}`
    }
    return `${config.siteUrl}${data.header.fluid.src}`
  }

  const creator = {
    '@context': 'http://schema.org',
    '@id': `${config.siteUrl}/#creator`,
    '@type': 'Person',
    name: 'Mihai Blaga',
    nationality: 'Romanian',
    image:
      'https://images.ctfassets.net/we1vmn896dlj/1D2jTyWEcDOCNQ8pj7vtLY/d514f8bf89d65392f10637e57e895100/profile-img.jpg',
    gender: 'male',
    url: config.siteUrl,
    email: 'hey@mihaiblaga.dev',
    jobTitle: 'Full Stack JavaScript Developer',
    sameAs: [
      'https://www.mihaiblaga.me',
      'https://github.com/mihaiblaga89',
      'https://www.linkedin.com/in/mihai-blaga/',
      'https://www.facebook.com/legolas8911',
    ],
  }

  const organization = {
    '@context': 'http://schema.org',
    '@id': `${config.siteUrl}/#organization`,
    '@type': 'Organization',
    name: 'mihaiblaga.dev',
    logo: {
      '@type': 'ImageObject',
      url:
        'https://images.ctfassets.net/we1vmn896dlj/1D2jTyWEcDOCNQ8pj7vtLY/d514f8bf89d65392f10637e57e895100/profile-img.jpg',
    },
    url: config.siteUrl,
  }

  //   const schemaBlogPost = {
  //     '@context': 'http://schema.org',
  //     '@type': 'BlogPosting',
  //     image,
  //     url,
  //     headline: 'My love of cats explained',
  //     alternativeHeadline: 'An indepth article on why I love cats',
  //     dateCreated: '2019-02-11T11:11:11',
  //     datePublished: '2019-02-11T11:11:11',
  //     dateModified: '2019-02-11T11:11:11',
  //     inLanguage: 'en-US',
  //     isFamilyFriendly: 'true',
  //     copyrightYear: '2019',
  //     copyrightHolder: '',
  //     contentLocation: {
  //       '@type': 'Place',
  //       name: 'Delray Beach, FL',
  //     },
  //     accountablePerson: {
  //       '@type': 'Person',
  //       name: 'Patrick Coombe',
  //       url: 'https://patrickcoombe.com',
  //     },
  //     author: {
  //       '@type': 'Person',
  //       name: 'Patrick Coombe',
  //       url: 'https://patrickcoombe.com',
  //     },
  //     creator: {
  //       '@type': 'Person',
  //       name: 'Patrick Coombe',
  //       url: 'https://patrickcoombe.com',
  //     },
  //     publisher: {
  //       '@type': 'Organization',
  //       name: 'Patrick Coombe',
  //       url: 'https://patrickcoombe.com',
  //       logo: {
  //         '@type': 'ImageObject',
  //         url: 'http://www.example.com/logo.png',
  //         width: '400',
  //         height: '55',
  //       },
  //     },
  //     sponsor: {
  //       '@type': 'Organization',
  //       name: 'Acme Widgets',
  //       url: 'https://example.com',
  //       logo: {
  //         '@type': 'ImageObject',
  //         url: 'http://www.example.com/logo.png',
  //       },
  //     },
  //     mainEntityOfPage: 'True',
  //     keywords: ['keyword1', 'keyword2', 'keyword3', 'keyword4'],
  //     genre: ['SEO', 'JSON-LD'],
  //     articleSection: 'Uncategorized posts',
  //     articleBody: 'Paste the body of your content in here in plaintext',

  //     url: URL,
  //     headline: siteHeadline,
  //     inLanguage: htmlLang,
  //     mainEntityOfPage: URL,
  //     description,
  //     name: title,
  //     author: {
  //       '@id': `${config.siteUrl}/#identity`,
  //     },
  //     copyrightHolder: {
  //       '@id': `${config.siteUrl}/#identity`,
  //     },
  //     copyrightYear: '2017',
  //     creator: {
  //       '@id': `${config.siteUrl}/#creator`,
  //     },
  //     publisher: {
  //       '@id': `${config.siteUrl}/#creator`,
  //     },
  //     datePublished: '2017-12-08',
  //     dateModified: buildTime,
  //     image: {
  //       '@type': 'ImageObject',
  //       url: image,
  //     },
  //   }

  let schemaArticle = null

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': config.siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  let URL

  if (article) {
    const {
      title,
      heroImage: {
        fluid: { src },
      },
      slug,
    } = article

    URL = `${config.siteUrl}/${slug}`
    schemaArticle = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': URL,
      },
      headline: title,
      image: [composeImage(src)],
      datePublished: article.publishDate,
      dateModified: article.publishDate,
      author: {
        '@id': `${config.siteUrl}/#creator`,
      },
      publisher: {
        '@id': `${config.siteUrl}/#organization`,
      },
    }
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': URL,
        name: title,
      },
      position: 3,
    })
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  const width = data.header.fluid.sizes.split(', ')[1].split('px')[0]
  const height = String(Number(width) / data.header.fluid.aspectRatio)

  const title = article?.title || config.title
  const description = article?.body?.childMarkdownRemark?.excerpt || config.description
  const image = composeImage(article?.heroImage?.fluid?.src)
  const imageWidth = parseInt(article?.width || width, 10)
  const imageHeight = parseInt(article?.height || height, 10)

  return (
    <Helmet>
      <html lang={config.lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={URL} />
      <meta property="og:image" content={image} />
      {config.facebook && <meta property="article:publisher" content={config.facebook} />}
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta httpEquiv="content-language" content="en-gb" />
      <meta name="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={article ? 'article' : 'website'} />
      {article && <meta property="article:published_time" content={article.publishDate} />}
      {article && <meta property="article:modified_time" content={article.publishDate} />}
      <meta property="og:image:alt" content={description || config.description} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="og:see_also" content="https://github.com/mihaiblaga89" />
      <meta property="og:see_also" content="https://www.linkedin.com/in/mihai-blaga" />
      <meta property="og:see_also" content="https://www.facebook.com/legolas8911" />
      <meta name="twitter:description" content={description || config.description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={description || config.description} />
      <meta name="twitter:image:width" content={imageWidth} />
      <meta name="twitter:image:height" content={imageHeight} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || config.title} />
      <meta name="twitter:url" content={config.siteUrl} />
      <meta name="twitter:site" content="@blaga_mihai" />
      <link type="text/plain" href={`${config.siteUrl}/humans.txt`} rel="author" />
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      <script type="application/ld+json">{JSON.stringify(creator)}</script>
      <script type="application/ld+json">{JSON.stringify(organization)}</script>
      {article && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
    </Helmet>
  )
}

const SEO = props => <StaticQuery query={querySEO} render={data => <Head {...props} data={data} />} />

export default SEO

Head.propTypes = {
  data: PropTypes.any.isRequired,
  article: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

Head.defaultProps = {
  article: false,
}

const querySEO = graphql`
  query SEO {
    header: contentfulAsset(contentful_id: { eq: "1O2kBOhTCgwAzDQt7DuxTS" }) {
      title
      fluid(maxWidth: 2000) {
        aspectRatio
        base64
        sizes
        src
        srcSet
        srcSetWebp
        srcWebp
      }
    }
    site {
      buildTime(formatString: "YYYY-MM-DD")
      config: siteMetadata {
        title
        description
        siteShortName
        siteUrl
        github
        coverImage
        linkedIn
        facebook
      }
    }
  }
`
